<app-header>
  <div class="container p-4">
    <div class="row">
      <div class="col-sm">
        <h1>Daybook</h1>
        <h2>the Teachers Daily Planner</h2>
        <p>A community focused, customer driven daily planner on your phone, tablet, or laptop. Available wherever you are, whenever you need it.</p>
        <p>Now Available in Australia, Canada, New Zeeland, UK & USA</p>
        <a target="_blank" href="https://app.daybook.com.au/auth/register"><div class="btn btn-light">Get Started</div></a>
        <br /><br />
      </div>
      <div class="col-sm">
        <div class="image-container m-4">
          <img src="assets/Tablet.png" width="75%">
        </div>
      </div>
    </div>
  </div>
</app-header>

<div>
  <div class="container p-4">
    <h2>Download from the app store</h2>
    <!--    <div class="alert alert-info mb-4">Google Play is coming soon. In the meantime you can still access Daybook on all devices via your web browser.</div>-->
    <a target="_blank" href="https://apps.apple.com/au/app/daybook-teacher-planner/id1542451662"><img src="assets/download-on-the-app-store-apple.svg" height="45px" /></a>
    <a target="_blank" href="https://play.google.com/store/apps/details?id=au.com.daybook.app"><img src="assets/google-play-badge.png" height="67px" /></a>
  </div>
</div>

<div>
  <div class="container p-4">
    <div class="row">
      <div class="jumbotron col-sm m-2">
        <h2>I think your product is great! Well done! It is just like my paper copy but more useful.</h2>
        <p><b> - Gretchen</b></p>
      </div>
      <div class="jumbotron col-sm m-2" >
        <h2>I used daybook last year and it was great! I am still using it and enjoying it.</h2>
        <p><b> - Hayley Kruger</b></p>
      </div>
      <div class="jumbotron col-sm m-2">
        <h2>I've been teaching for over 30 years and your APP is the closest that I've found to a daybook.</h2>
        <p><b> - Thekla</b></p>
      </div>
    </div>
  </div>
</div>
<div>
</div>
<!--<div>-->
<!--  <div class="container p-4">-->
<!--    <h2>Download from the app store</h2>-->
<!--&lt;!&ndash;    <div class="alert alert-info mb-4">Google Play is coming soon. In the meantime you can still access Daybook on all devices via your web browser.</div>&ndash;&gt;-->
<!--    <a target="_blank" href="https://apps.apple.com/au/app/daybook-teacher-planner/id1542451662"><img src="assets/download-on-the-app-store-apple.svg" height="45px" /></a>-->
<!--    <a target="_blank" href="https://play.google.com/store/apps/details?id=au.com.daybook.app"><img src="assets/google-play-badge.png" height="67px" /></a>-->
<!--  </div>-->
<!--</div>-->
<!--<div>-->
<!--  <div class="container p-4">-->
<!--    <h2>Student Diaries</h2>-->
<!--    <a routerLink="/community"><div class="btn btn-primary" >Contact Us</div></a>-->
<!--  </div>-->
<!--</div>-->

